import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_info_card = _resolveComponent("info-card")!
  const _component_info_cards = _resolveComponent("info-cards")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { breadcrumbs: _ctx.breadcrumbs }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Spam filter",
        icon: "report",
        class: "mb-4"
      }),
      _createVNode(_component_info_cards, null, {
        default: _withCtx(() => [
          _createVNode(_component_info_card, {
            title: "Blacklist",
            bordered: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_form_line, {
                label: "Blacklisted emails and domains",
                class: "max-w800px mb-4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_field, {
                    modelValue: _ctx.state.blacklist,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.blacklist) = $event)),
                    type: "editableBadges",
                    placeholder: "Enter domain here",
                    message: "All incoming email / tickets from these domains will be blocked and not reach your inbox."
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_tm_form_line, {
                label: "Blacklisted phrases",
                class: "max-w800px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_field, {
                    modelValue: _ctx.state.blacklistPhrases,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.blacklistPhrases) = $event)),
                    type: "editableBadges",
                    placeholder: "Enter phrase here",
                    message: "List of phrases which will trigger the incoming ticket to end up in the spam folder. The sender will not be blocked though."
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_info_card, {
            title: "Whitelist",
            bordered: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_form_line, {
                label: "Whitelisted emails and domains",
                class: "max-w800px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_field, {
                    modelValue: _ctx.state.whitelist,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.whitelist) = $event)),
                    type: "editableBadges",
                    placeholder: "Enter email or domain here",
                    message: "Incoming emails / tickets  from these addresses will never end up in the spam folder regardless of the content of the email."
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}