
import { defineComponent, watch, onMounted, onBeforeUnmount, reactive } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import { useBottomBar } from '@/compositions/bottomBar'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmFormLine,
    PageContent,
    DetailsHero,
    InfoCards,
    InfoCard,
  },
  setup() {
    const { provideData, getDiscardFunc } = useBottomBar()
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Spam filter', url: '' },
    ]

    const blacklist = [
      { text: 'callie.me', valid: true },
      { text: 'reyna.us', valid: true },
      { text: 'shyanne.biz', valid: true },
      { text: 'hotmailer.com', valid: true },
    ]

    const blacklistPhrases = [
      { text: 'buy indian bags', valid: true },
      { text: 'design agency', valid: true },
      { text: 'yes this is spam, and what?', valid: true },
      { text: 'development', valid: true },
      { text: 'help to buy', valid: true },
    ]

    const whitelist = [
      { text: 'laisha.com', valid: true },
      { text: 'bayer.biz', valid: true },
      { text: 'zackary.donnelly@yahoo.com', valid: true },
      { text: 'gerry.tv', valid: true },
      { text: 'adaline_wucket@ebert.ca', valid: true },
      { text: 'connor_pfannerstill@lucy.biz', valid: true },
    ]

    const state = reactive({
      blacklist: isEmptyMode.value
        ? []
        : blacklist,
      blacklistPhrases: isEmptyMode.value
        ? []
        : blacklistPhrases,
      whitelist: isEmptyMode.value
        ? []
        : whitelist,
    })

    const defaultState = Object.assign({}, state)

    watch(() => state, (newValue) => {
      JSON.stringify(newValue) !== JSON.stringify(defaultState) ? provideData(1) : getDiscardFunc.value()
    }, { deep: true })

    const restoreEnabled = () => {
      Object.assign(state, defaultState)
    }

    onMounted(() => {
      provideData(0, restoreEnabled)
    })

    onBeforeUnmount(() => {
      getDiscardFunc.value()
    })

    return {
      breadcrumbs,
      state,
    }
  },
})
